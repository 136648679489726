@import 'tailwindcss/base';

@layer base {
  h1 {
    @apply text-3xl;
    @apply mx-2.5;
    @apply my-2.5;
  }
  h2 {
    @apply text-3xl;
    @apply mx-2.5;
    @apply my-2.5;
  }
  h3 {
    @apply text-2xl;
    @apply mx-2.5;
    @apply my-2.5;
  }
  h4 {
    @apply text-xl;
    @apply mx-2.5;
    @apply my-2.5;
  }
  h5 {
    @apply text-lg;
    @apply mx-2.5;
    @apply my-2.5;
  }

}

@import 'tailwindcss/components';

@layer components {
    .especstab_bg div {
        @apply !bg-[#1E429F];
        @apply !border !border-sky-700;
    }
    .especstab_bg ul li{
        border-style:none !important;
    }
}

@import 'tailwindcss/utilities';

